<template>
  <div class="box">
    <van-list v-model="loading" :finished="finished"
      :finished-text="$t('public.noMore')" @load="onLoad"
    >
      <div class="list-box">
        <van-cell-group :style="{ textAlign: 'left' }">
          <div v-for="(item, index) in list" :key="index">
            <van-cell :title="item.name" :label="item.post"
              is-link @click="bindClick(item, item.id)"
            />
          </div>
        </van-cell-group>
      </div>
    </van-list>
  </div>
</template>

<script>
import BASE from "../../../utils/base";
export default {
  name: "IndexInvolvingRights",
  components: {},
  activated() {
    console.log("indexDepInterest");
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "",
    },
    departId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 列表控制
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
    };
  },
  watch: {
    id() {
      this.finished = false;
      this.list = [];
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };
      this.loading = true;
      this.onLoad();
    },
  },
  mounted() {},
  computed: {},
  methods: {
    // 数据加载
    onLoad() {
      // 请求参数
      const params = {
        ...this.requestData,
        departId: this.$props.id,
        pid: 0,
      };

      const url =
        this.sign === "depart"
          ? "zxSupervisePowerRelatives/rootList"
          : "zxSupervisePowerRelatives/rootListCode";

      this.axios
        .get(BASE.genUrl(url, params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;

          if (data.code !== 200) this.finished = true;

          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
        });
    },
    bindClick(e, id) {
      sessionStorage.setItem("InvolvingRightsInfo", JSON.stringify(e));
      this.$router.push({
        name: "index_involving_rights_detal",
        query: { id },
      });
    },
  },
};
</script>

<style scoped>
.box {
}

.van-cell {
  align-items: center;
}
</style>
