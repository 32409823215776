<template>
  <div class="box">
    <div class="tabs">
      <div class="tab3-top">
        <Btn v-for="(item, index) in areaList" :key="index"
          :name="item.name" :info="item"
          @getOrgInfo="getOrgInfo1"
        />
      </div>
      <div class="tab3-top" v-if="area2List && area2List.length > 0">
        <!-- <Btn v-for="(item, index) in area2List" :key="index"
          :name="item.name" :info="item"
          @getOrgInfo="getOrgInfo2"
          :customStyle="{ background: '#ccc', color: '#b51414' }"
        /> -->
		<Btn
		  :name="area2List[0].name" :info="area2List[0]"
		  @getOrgInfo="getOrgInfo2"
		  :customStyle="{ background: '#ccc', color: '#b51414' }"
		/>
      </div>
      <div v-if="area3List">
        <ul class="orgfundlist">
          <Btn v-for="(item, index) in area3List" :key="index"
            :name="item.name" :info="item"
            @getOrgInfo="getOrgInfo3"
            v-show="getOrgInfoShowId == null || getOrgInfoShowId === item.id"
          />
          <li v-show="getOrgInfoShowId != null"
            class="item" style="color: #fff"
            @click="backOrg3InfoTab"
          >
            <span>&lt;&lt;{{ $t('public.goBack') }}</span>
          </li>
        </ul>
      </div>
      <div class="orgfundlist" v-if="area4List">
        <Btn v-for="(item, index) in area4List" :key="index"
          :name="item.name" :info="item"
          @getOrgInfo="getOrgInfo4"
          :customStyle="{ background: '#ccc', color: '#000' }"
          :active="getArea4InfoId == item.id"
        />
      </div>

      <div class="org-child" v-if="showOrgInfo">
        <Tab :list="tabList" :active="tabActive"
          :customStyle="{ border: '1px solid #ccc', borderBottom: 'none' }"
          @tabChange="tabChange"
        />
        <IndexDepInterest v-if="tabActive == 0"
          :id="getArea4InfoCode" :sign="sign"
          :departId="getArea4InfoCode"
        />
        <IndexDepObject v-if="tabActive == 1"
          :id="getArea4InfoId" :sign="sign"
          :departId="getArea4InfoCode"
        />
        <IndexDepRectify v-if="tabActive == 2"
          :id="getArea4InfoCode" :sign="sign"
          :name="getArea4InfoName"
        />
        <IndexDepProject v-if="tabActive == 3"
          :id="getArea4InfoCode"
          :departId="getArea4InfoCode"
        />
        <IndexInvolvingRights v-if="tabActive == 4"
          :id="getArea4InfoCode" :sign="sign"
          :departId="getArea4InfoCode"
        />
      </div>
    </div>
    <VoicePlay :content="ZY_writtenWords" />
  </div>
</template>
<script>
import BASE from "../../../utils/base";

import Btn from "../components/Btn";
import Tab from "../components/Tab";

import IndexDepInterest from "./indexDepInterest";
import IndexDepObject from "./indexDepObject";
import IndexDepRectify from "./indexDepRectify";
import IndexDepProject from "./indexDepProject";
import IndexInvolvingRights from "./indexInvolvingRights";
import VoicePlay from "@/components/VoicePlay";

export default {
  name: "indexRural",
  components: {
    Btn,
    Tab,
    IndexDepInterest,
    IndexDepObject,
    IndexDepRectify,
    IndexDepProject,
    IndexInvolvingRights,
    VoicePlay,
  },
  activated() {
    // this.tabActive = 0
  },
  created() {
    console.log("indexRural --- created");
    this.getArea();
  },
  props: {
    sign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 标签页
      tabList: [
        { name: this.$t('supervise.level2TabList.interest') },
        { name: this.$t('supervise.level2TabList.object') },
        { name: this.$t('supervise.level2TabList.rectify') },
        { name: this.$t('supervise.level2TabList.project') },
        { name: this.$t('supervise.level2TabList.involvingRights') },
      ],
      // 当前选中标签页
      tabActive: 0,

      // 一级
      areaList: [],
      // 二级
      area2List: [],
      // 三级
      area3List: [],
      // 四级
      area4List: [],

      getOrgInfoShowId: null,

      // getArea4Info: false,

      showOrgInfo: false,

      getArea4InfoId: "",
      getArea4InfoCode: "",
      getArea4InfoName: "",
      // 语音播报组件征用字段
      ZY_writtenWords: "",
    };
  },
  methods: {
    /**
     * 区域查询 - 一级
     */
    getArea() {
      const that = this;
      // 区域查询
      this.axios
        .get(
          BASE.genUrl("zxCommAddress/rootList", {
            pid: "1d2bb3af77ee4ae4bf8871067233febf",
          })
        )
        .then((res) => {
          console.log("区域查询", res);
          let data = res.data;
          if (data.result) {
            data.result.records.forEach((item) => {
              if (item.name === "昌吉回族自治州") {
                that.areaList = [item];
                that.getArea2(item.id);
              }
            });
            // that.areaList = data.result.records;
          }
        });
    },
    /**
     * 区域查询 - 二级
     */
    getArea2(id) {
      console.log("区域查询 - 二级");
      let that = this;
      this.area3List = [];
      this.area4List = [];
      this.getOrgInfoShowId = null;
	  console.log("区域查询 - 二级11111111");
      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          console.log("区域查询222", res, id);
          let data = res.data;
          // console.log("区域查询222", data.result);
          if (data.result) {
            that.area2List = data.result.records;
            that.getArea3(
              data.result.records[0].id,
              data.result.records[0].name
            );
          }
        });
    },
    /**
     * 区域查询 - 三级
     */
    getArea3(id, name) {
      // console.log("区域查询 - 三级");
      let that = this;
      this.area4List = [];
      this.getOrgInfoShowId = null;
      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          // console.log("区域二级查询", res);
          let data = res.data;
          if (data.result) {
            for (let i = 0; i < data.result.records.length; i++) {
              data.result.records[i].address =
                name + data.result.records[i].name;
            }
            that.area3List = data.result.records;
          }
        });
    },
    /**
     * 区域查询 - 四级
     */
    getArea4(id, name, item) {
      // console.log("区域查询 - 四级");
      let that = this;
      this.getOrgInfoShowId = id;

      this.getArea4InfoId = item.id;
      this.getArea4InfoCode = item.code;
      this.getArea4InfoName = item.name;
      this.showOrgInfo = true;

      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          // console.log("区域三级查询", res);
          let data = res.data;
          if (data.result) {
            for (let i = 0; i < data.result.records.length; i++) {
              data.result.records[i].address =
                name + data.result.records[i].name;
            }
            that.area4List = data.result.records;
          }
        });
    },
    getArea4Info(item) {
      console.log(item);
      this.getArea4InfoId = item.id;
      this.getArea4InfoCode = item.code;
      this.getArea4InfoName = item.name;
      this.showOrgInfo = true;
      // this.org3List = [];
      // this.changeTab3Tab(1);
    },
    // 点击按钮回调事件区域开始
    getOrgInfo1(item, play = false) {
      // console.log("一级按钮:::::", item, play);
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = item.name;

      this.getArea2(item.id);
    },
    getOrgInfo2(item, play = false) {
      // console.log("二级按钮", item);
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = item.name;
      this.getArea3(item.id, item.name);
    },
    getOrgInfo3(item, play = false) {
      // console.log("三级按钮", item);
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = item.name;
      this.getArea4(item.id, item.name, item);
    },
    getOrgInfo4(item, play = false) {
      // console.log("四级按钮", item);
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = item.name;
      this.getArea4Info(item);
    },
    // 返回
    backOrg3InfoTab() {
      this.getOrgInfoShowId = null;
      this.area4List = [];
      // this.getArea4InfoId = '';
    },
    // 点击按钮回调事件区域结束

    /**
     * 切换分页
     */
    tabChange(index) {
      // 添加公共语音播报
      this.ZY_writtenWords = this.tabList[index].name;
      this.tabActive = index;
    },
  },
};
</script>
<style scoped>
.box {
}
.tabs {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
.tab3-top {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-bottom: 3px;
}
/* 二级样式 */
.tab3-top .area2List,
.tab3-top .area4List {
  background: #ccc;
  color: #b51414;
  border-radius: 0.5rem;
}

/* 三级 ul */
.area3List a {
  color: #fe9b4a;
  font-size: 12px;
}

/* 四级 */
.area4List {
  color: black;
}

.orgfundlist {
  padding: 0.2rem;
  text-align: left;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

#tab3 .orgfundlist .active {
  color: #fe9b4a;
  background: #b51414;
}

/* 按钮样式 */
.item {
  flex: 0 0 32%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;

  font-size: 12px;
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.org-child {
  margin-top: 0.5rem;
}
</style>
