<template>
  <div class="box">
    <div class="list-box">
      <van-list v-model="loading" :finished="finished"
        :finished-text="$t('public.noMore')" @load="onLoad"
      >
        <div class="list-box" v-for="(item, index) in list" :key="index">
          <van-panel class="panel-custom">
            <div class="panel-header" slot="header">
              <div class="header-left">{{ item.name }}</div>
              <!-- <div class="header-right" >{{item.status}}</div> -->
            </div>
            <div>
              <van-cell :border="false" value-class="cell-value"
                :value="item.planyear" :title="$t('supervise.project.titleList.planyear')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.area" :title="$t('supervise.project.titleList.area')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.pno" :title="$t('supervise.project.titleList.pno')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.porno" :title="$t('supervise.project.titleList.porno')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.company" :title="$t('supervise.project.titleList.company')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.type" :title="$t('supervise.project.titleList.type')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.starttime" :title="$t('supervise.project.titleList.starttime')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.endtime" :title="$t('supervise.project.titleList.endtime')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.price" :title="$t('supervise.project.titleList.price')"
              />
              <van-cell :border="false" value-class="cell-value"
                :value="item.remark" :title="$t('supervise.project.titleList.remark')"
              />
            </div>
            <div slot="footer" style="text-align: right">
              <van-button type="danger" size="mini" @click="bindTousu(index)">
                {{ $t('casually.feedback') }}
              </van-button>
            </div>
            <!-- <div slot="footer" style="text-align: center;color: #ee0a24;font-size: 14px; font-weight: bold">
              投  诉
            </div> -->
          </van-panel>
        </div>
      </van-list>
    </div>

    <Tousu
      :moneyId="moneyId"
      :departId="departId"
      :remark="remark"
      :addsan="addsan"
    />
  </div>
</template>
<script>
import BASE from "../../../utils/base";

import Tousu from "../components/Tousu";
export default {
  name: "indexDepProject",
  components: { Tousu },
  activated() {
    this.onLoad();
    console.log("indexDepProject");
  },
  mounted() {
    console.log("indexDepProject - mounted - " + this.$props.id);
    // this.onLoad()
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    departId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],

      // 投诉弹窗控制
      moneyId: "",
      remark: "",
      addsan: true,
      // departId: ""
    };
  },
  watch: {
    id(newV, oldV) {
      console.log(newV, oldV);
      if (newV === oldV) return;
      // this.loading = false
      this.finished = false;
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };
      this.list = [];
      this.loading = true;
      this.onLoad();
    },
  },
  methods: {
    // 手风琴数据加载
    onLoad() {
      console.log("indexDepProject -- onLoad");
      // 请求参数
      const params = {
        ...this.requestData,
        // sysOrgCode: this.$props.id
        depid: this.$props.id,
      };
      this.axios
        .get(BASE.genUrl("zxSuperviseDepproject/list", params))
        .then((res) => {
          let data = res.data;

          if (data.code !== 200) this.finished = true;

          // 加载状态结束
          this.loading = false;
          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.finished = true;
        });
    },
    bindTousu(i) {
      const item = this.list[i];
      // this.departId = item.depid
      this.moneyId = item.id;

      this.$modal.show("modal");
    },
  },
};
</script>
<style scoped>
.box {
  background-color: #f4f4f4;
}
.list-box {
}
.panel-custom {
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  background-color: #fff;
  border-radius: 1rem;
  border: none;
}
.panel-header {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  /* line-height: 2.3rem; */
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
  display: flex;
}
.panel-header .header-left {
  flex: 1;
}
.panel-header .header-right {
  color: #b51414;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.cell-value {
  flex: 2;
}

.cell-group-title-custom {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  line-height: 2.3rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
}
.cell-group-title-custom span {
  padding-left: 0.5rem;
  border-left: 3px solid #b51414;
}

.van-cell {
  padding: 0.2rem 1rem;
}
</style>
