<template>
  <div
    class="btn-item"
    :class="active ? 'active' : ''"
    :style="customStyle"
    @click="bindClick"
  >
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "Btn",
  props: {
    // 自定义样式
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    // 按钮名称
    name: {
      type: String,
      default: "按钮",
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    bindClick() {
      this.$emit("getOrgInfo", this.$props.info, true);
    },
  },
};
</script>

<style scoped>
.btn-item {
  flex: 0 0 32%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;

  font-size: 12px;
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.active {
  color: #fe9b4a !important;
  background: #b51414 !important;
}
</style>
