<template>
  <div class="box">
    <van-list v-model="loading" :finished="finished"
      :finished-text="$t('public.noMore')"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index">
        <router-link
          :to="{ name: 'index_super_detail', query: { id: item.id } }"
        >
          <van-cell
            :title="item.name"
            :is-link="true"
            :style="{ textAlign: 'left' }"
          />
        </router-link>
      </div>
    </van-list>
  </div>
</template>

<script>
import BASE from "../../../utils/base";
export default {
  name: "IndexSuper",
  components: {},
  activated() {
    this.onLoad();
  },
  data() {
    return {
      loading: true,
      finished: false,
      list: [],
      // 请求数据
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    onLoad() {
      // 请求参数
      const params = { ...this.requestData };

      this.axios
        .get(BASE.genUrl("zxSupervisePowerlistImg/list", params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;

          if (data.code !== 200) this.finished = true;

          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style scoped>
.box {
  border-radius: 0 0 0.5rem 0.5rem;
  /* overflow: hidden; */
  background-color: #fff;
}
</style>
