<template>
  <div class="box">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('public.noMore')"
      @load="onLoad"
    >
      <div class="list-box" v-for="(item, index) in group" :key="index">
        <van-panel :title="item">
          <div class="panel-header" slot="header">
            <span>{{ item }}</span>
          </div>
          <div>
            <!-- 宫格形式 -->
            <!-- <van-grid>
              <van-grid-item
                v-for="(item1, index1) in item.list" :key="index1" 
              >
                <div slot="icon">
                  <img :src="item1.imgSrc" alt="">
                  <img class="grid-item-icon" src="../../../assets/default/head1.png" alt="">
                </div>
                <div class="grid-item-text" slot="text">
                  <div class="grid-item-title">{{item1.name}}</div>
                  <div class="grid-item-label">{{item1.duties}}</div>
                </div>
              </van-grid-item>
            </van-grid> -->
            <!-- 列表形式 -->
            <van-cell
              :title="item1.name"
              :label="item1.duties"
              :style="{ textAlign: 'left' }"
              v-for="(item1, index1) in list[index]"
              :key="index1"
            >
              <div slot="icon" class="between-center left-icon" style="">
                <!-- <img class="grid-item-icon" :src="item1.headurl" alt=""> -->
                <van-image
                  round
                  width="2.3rem"
                  height="2.3rem"
                  :src="item1.headurl"
                />
              </div>
              <div slot="right-icon" class="between-center right-icon">
                <van-button
                  plain
                  type="danger"
                  size="mini"
                  @click="bindTousu(index, index1)"
                  >{{ $t('casually.feedback') }}</van-button
                >
              </div>
            </van-cell>
          </div>
        </van-panel>
      </div>
    </van-list>

    <Tousu
      :moneyId="moneyId"
      :departId="departId"
      :remark="remark"
      :addsan="addsan"
    />
  </div>
</template>
<script>
import BASE from "../../../utils/base";
import Tousu from "../components/Tousu";
export default {
  name: "indexDepObject",
  components: { Tousu },
  activated() {},
  props: {
    id: {
      type: String,
      default: "",
    },
    departId: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 投诉弹窗控制
      moneyId: "",
      remark: "",
      addsan: true,
      // 列表控制
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      
      list: [],
    };
  },
  computed: {
    group() {
      let arr = [];
      if (this.$props.sign === "depart") {
        // 部门监督
        arr = [
          this.$t('supervise.departGroup.charge'),
          this.$t('supervise.departGroup.teamMembers'),
          this.$t('supervise.departGroup.postKeyPerson'),
          this.$t('supervise.departGroup.otherCadres'),
          this.$t('supervise.departGroup.windowStaff'),
        ];
      } else {
        // 乡村监督
        arr = [
          this.$t('supervise.RuralGroup.leadCadre'), 
          this.$t('supervise.RuralGroup.other')
        ];
      }

      return arr;
    },
    /* list() {
      let groupLen = this.group.length

      return Array(groupLen).fill([])
    }  */
  },
  watch: {
    id(newV, oldV) {
      if (newV === oldV) return;
      // this.loading = false
      this.finished = false;
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };

      // this.list = [[], [], [], [], []]
      this.fillList();
      this.loading = true;
      this.onLoad();
    },
  },
  mounted() {
    this.fillList();
  },
  methods: {
    // 数据加载
    async onLoad() {
      const that = this;
      // 部门监督 / 乡村监督
      // let urlStr = this.$props.sign === "depart" ? "zxSuperviseBasedataVillage/list" : "zxSuperviseBasedataServant/list"
      let urlStr =
        this.$props.sign !== "depart"
          ? "zxSuperviseBasedataVillage/list"
          : "zxSuperviseBasedataServant/list";

      const params = {
        ...this.requestData,
        depId: this.$props.id,
      };
      await this.axios.get(BASE.genUrl(urlStr, params)).then((res) => {
        let data = res.data;
        // 加载状态结束
        that.loading = false;

        if (data.result) {
          const records = data.result.records;
          let arr = that.list;

          for (const e of records) {
            const indof = that.group.indexOf(e.mygroup);
            if (indof >= 0) {
              // arr[indof].push(e)
              arr[indof] = [...arr[indof], e];
            } else {
              // arr[arr.length-1].push(e)
              let arrIndex = arr.length - 1;
              arr[arrIndex] = [...arr[arrIndex], e];
            }
          }
          that.list = arr;
          // 数据全部加载完成
          if (data.result.records.length < this.requestData.pageSize) {
            that.finished = true;
          }
          that.requestData.pageNo += 1;
        }
      });
    },
    bindTousu(oneLevel, twoLevel) {
      this.moneyId = this.list[oneLevel][twoLevel].id;
      this.$modal.show("modal");
    },
    fillList() {
      let groupLen = this.group.length;

      this.list = Array(groupLen).fill([]);
    },
  },
};
</script>
<style scoped>
/* .box {} */
.list-box {
  margin: 0.5rem 0;
}

.panel-header {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  line-height: 2.3rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
}
.panel-header span {
  padding-left: 1rem;
  border-left: 3px solid #b51414;
}

.grid-item-icon {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
}
.grid-item-text {
  /* width: 5.73rem; */
  /* padding: 0; */
}
.grid-item-title {
  font-size: 0.8rem;
  width: 100%;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.grid-item-label {
  /* font-size: .8rem; */
  color: #ccc;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.between-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-icon {
  margin-right: 0.5rem;
}
.right-icon {
  margin-left: 0.5rem;
}
</style>
