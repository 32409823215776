<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <Tab :list="tabList" :active="tabActive" @tabChange="tabChange" />

      <!-- <van-swipe 
        :loop="false" :show-indicators="false" :touchable="false"
        :initial-swipe="tabActive" class="swiper-box" style=""
      >
        <van-swipe-item>
          部门监督
          <IndexDep />
        </van-swipe-item>
        <van-swipe-item>
          乡村监督
          <IndexRural />
        </van-swipe-item>
      </van-swipe> -->
      <!-- 部门监督 -->
      <IndexDep ref="depChild" sign="depart" v-show="tabActive === 0" />
      <!-- 乡村监督 -->
      <IndexRural ref="ruralChild" sign="rural" v-show="tabActive === 1" />
      <!-- 权利监督 -->
      <IndexSuper ref="indexSuper" v-show="tabActive === 2" />

      <!-- 底部内容 -->
      <Footer />
    </div>
    <VoicePlay :content="ZY_writtenWords" />
  </div>
</template>
<script>
import Footer from "../../components/Footer";
import Tab from "./components/Tab";

import IndexDep from "./indexChild/indexDep";
import IndexRural from "./indexChild/indexRural";
import IndexSuper from "./indexChild/indexSuper";
import VoicePlay from "@/components/VoicePlay";

export default {
  name: "app",
  components: { Footer, Tab, IndexDep, IndexRural, IndexSuper, VoicePlay },
  activated() {},
  data() {
    return {
      // 标签页
      tabList: [
        { name: this.$t('supervise.tabList.depart') },
        { name: this.$t('supervise.tabList.rural') },
        { name: this.$t('supervise.tabList.super') },
      ],
      // 当前选中标签页
      tabActive: 0,
      // 语音播报组件征用字段
      ZY_writtenWords: "",
    };
  },
  methods: {
    /**
     * 改变选中tab页
     */
    tabChange(index) {
      console.log(index);
      // 添加公共语音播报
      this.ZY_writtenWords = this.tabList[index].name;

      this.tabActive = index;
    },
  },
};
</script>
<style scoped>
.container {
  /* height: calc(100vh - 97px); */
}
.swiper-box {
  height: 200px;
  background: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
