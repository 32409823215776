<template>
  <div class="box">
    <van-list v-model="loading" :finished="finished"
      :finished-text="$t('public.noMore')"
      @load="onLoad"
    >
      <div class="list-box" v-for="(item, index) in list" :key="index">
        <van-panel class="panel-custom">
          <div class="panel-header" slot="header">
            <div class="header-left">{{ item.title }}</div>
            <div class="header-right">{{ item.status }}</div>
          </div>
          <div>
            <!-- <van-cell-group :border="false"> -->
            <!-- <div slot="title" class="cell-group-title-custom">
                <span>详细信息</span>
              </div> -->
            <van-cell :border="false" value-class="cell-value"
              :value="item.type" :title="$t('supervise.rectify.letterType')"
            />
            <van-cell :border="false" value-class="cell-value"
              :value="item.state" :title="$t('supervise.rectify.state')"
            />
            <van-cell :border="false" value-class="cell-value"
              :value="item.createTime" :title="$t('supervise.rectify.createTime')"
            />
            <!-- </van-cell-group>
            <van-cell-group :border="false"> -->
            <!-- <div slot="title" class="cell-group-title-custom">
                <span>回复信息</span>
              </div> -->
            <van-cell :border="false" value-class="cell-value"
              :value="name" :title="$t('supervise.rectify.name')"
            />
            <van-cell :border="false" value-class="cell-value"
              :label="item.backcontent || $t('public.noData')" 
              :title="$t('supervise.rectify.backcontent')"
            />
            <van-cell :border="false" value-class="cell-value"
              :value="item.updateTime || $t('public.noData')" 
              :title="$t('supervise.rectify.updateTime')"
            />
            <!-- </van-cell-group> -->
          </div>
        </van-panel>
      </div>
    </van-list>
  </div>
</template>
<script>
import BASE from "../../../utils/base";
export default {
  name: "indexDepRectify",
  components: {},
  activated() {},
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
    };
  },
  watch: {
    id(newV, oldV) {
      console.log(newV, oldV);
      // this.loading = false
      this.finished = false;
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };
      // this.onLoad()
      this.list = [];

      this.loading = true;
      this.onLoad();
    },
  },
  methods: {
    // 数据加载
    onLoad() {
      console.log("indexDepRectify - onLoad -----------");
      // 请求参数
      const params = {
        ...this.requestData,
        state: "完结",
        // sysOrgCode: this.$props.id
        departId: this.$props.id,
      };

      const url =
        this.sign !== "depart"
          ? "zxSuperviseLetter/listcode"
          : "zxSuperviseLetter/list";

      this.axios
        .get(BASE.genUrl(url, params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;
          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>
<style scoped>
.box {
  background-color: #f4f4f4;
}
.panel-custom {
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  background-color: #fff;
  border-radius: 1rem;
  border: none;
}
.panel-header {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  /* line-height: 2.3rem; */
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
  display: flex;
}
.panel-header .header-left {
  flex: 1;
}
.panel-header .header-right {
  color: #b51414;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.cell-value {
  flex: 2;
}

.cell-group-title-custom {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  line-height: 2.3rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
}
.cell-group-title-custom span {
  padding-left: 0.5rem;
  border-left: 3px solid #b51414;
}
.van-cell {
  padding: 0.2rem 1rem;
}
</style>
