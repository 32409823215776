<template>
  <div class="box">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('public.noMore')"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index">
        <router-link
          :to="{
            name: 'interest_detail',
            query: { id: item.powerId, departId: departId },
          }"
        >
          <van-cell
            :title="item.remark"
            :is-link="true"
            :style="{ textAlign: 'left' }"
          />
        </router-link>
      </div>
    </van-list>
  </div>
</template>
<script>
import BASE from "../../../utils/base";
export default {
  name: "indexDepInterest",
  components: {},
  activated() {
    console.log("indexDepInterest");
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "",
    },
    departId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },
      // 展示列表
      list: [],
    };
  },
  watch: {
    id() {
      this.finished = false;
      this.list = [];
      this.requestData = {
        pageNo: 1,
        pageSize: 20,
      };
      this.loading = true;
      this.onLoad();
    },
  },
  methods: {
    // 数据加载
    onLoad() {
      console.log("indexDepInterest - onLoad -----------");
      // 请求参数
      const params = {
        ...this.requestData,
        // sysOrgCode: this.$props.id
        depId: this.$props.id,
      };

      const url =
        this.sign === "depart"
          ? "zxSuperviseDeppower/list"
          : "zxSuperviseDeppower/listcode";

      this.axios
        .get(BASE.genUrl(url, params))
        .then((res) => {
          let data = res.data;
          // 加载状态结束
          this.loading = false;

          if (data.code !== 200) this.finished = true;

          if (data.result) {
            this.list = [...this.list, ...data.result.records];
            this.requestData.pageNo += 1;
            // 数据全部加载完成
            if (data.result.records.length < this.requestData.pageSize) {
              this.finished = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>
<style scoped>
.box {
}
.collapse-item-title {
  text-align: left;
}
.custom-title {
  border: 1px solid red;
}
</style>
